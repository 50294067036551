import View from './View.js'

const tag = '[MainContainerView]'
const MainContainerView = Object.create(View)

MainContainerView.setup = function(el) {
  this.init(el)
  this.bindEvents()
  return this
}

MainContainerView.setDivHeight = function() {
  const minHeight = document.body.offsetHeight - document.querySelector('.l-header').offsetHeight - document.querySelector('.l-footer').offsetHeight
  this.el.style.minHeight = minHeight + 'px'
}

MainContainerView.bindEvents = function() {
  window.addEventListener('load', e => this.onLoad())

  let timer = null
  window.addEventListener('resize', e => this.onResize(timer))
}

MainContainerView.onLoad = function() {
  this.setDivHeight()
}

MainContainerView.onResize = function(timer) {
  const delay = 100
  clearTimeout(timer)
  timer = setTimeout(this.onLoad(), delay);
}

export default MainContainerView
