import View from './View.js'
import flatpickr from 'flatpickr'

const tag = '[FormView]'
const FormView = Object.create(View)

const config = {
    mode: 'range',
    dateFormat: 'Y-m-d',
    // defaultDate: ['2018-10-10', '2018-10-20'],
    wrap: true
}

FormView.setup = function(el) {
  this.init(el)
  this.bindEvents()
  return this
}

FormView.bindEvents = function() {
  window.addEventListener('DOMContentLoaded', e => this.onLoad())
  const cancelDateSelect = () => {
    Array.from(document.querySelectorAll('[name=date_selector]')).forEach(radio => {
      radio.checked=false
    })
  }
  if ( document.getElementById('cancel_date') )
    document.getElementById('cancel_date').addEventListener('click', cancelDateSelect)
  const dateChangeHandler = (radio) => {
    const date_from = radio.dataset.date
    const date_to = radio.dataset.current
    let calendar = flatpickr('.flatpickr', config)
    calendar.setDate([date_from, date_to])
  }
  if ( document.querySelector('[name=date_selector]') )
    Array.from(document.querySelectorAll('[name=date_selector]')).forEach(radio => {
      radio.addEventListener('click', e => dateChangeHandler(radio))
    })

}

FormView.onLoad = function() {
  flatpickr('.flatpickr', config)
  // const url = new URL(window.location.href)
  // const date_selector = url.searchParams.get('date_selector')
	const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(m,key,value) {
      vars[key] = value;
    });
    return vars;
  }
  const date_selector = getUrlVars()['date_selector']
  if ( date_selector ) {
    const radio = document.querySelector('[data-date="' + date_selector + '"]')
    if ( radio ) radio.click()
  }
}

export default FormView
