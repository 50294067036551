import View from './View.js'

const tag = '[TabView]'
const TabView = Object.create(View)

TabView.tabNames = {
  Student: 'Student',
  NonCommercial: 'Non-Commercial',
  Commercial: 'Commercial',
  MyInfo: '내정보',
  MypagePurchaseList: '구매내역'
}

TabView.setup = function(el) {
  this.init(el)
  this.trigger = document.querySelector('.l-lnb [data-toggle=collapse]')
  this.bindEvents()
  return this
}

TabView.setActiveTab = function (tabName) {
  Array.from(this.el.children).forEach(li => {
    li.className = li.innerHTML === tabName ? 'active' : ''
  })
  this.show()
}

TabView.ariaToggle = function(togglers) {
  if (togglers.getAttribute('aria-expanded') == 'false') {
    togglers.setAttribute('aria-expanded', 'true')
  } else {
    togglers.setAttribute('aria-expanded', 'false')
  }
}

TabView.bindEvents = function() {
  this.trigger.addEventListener('click', e => this.toggleCollapse(e))
  Array.from(this.el.children).forEach(li => {
    li.addEventListener('click', e => this.onClick(li.innerText))
  })
}

TabView.toggleCollapse = function(e) {
  if (window.matchMedia(`screen and (max-width: 992px)`).matches) {
    this.el.classList.toggle('show')
    this.el.classList.toggle('collapsed')
    this.ariaToggle(e.currentTarget)
  }
}

TabView.onClick = function (tabName) {
  this.setActiveTab(tabName)
  this.emit('@change', { tabName })
}


export default TabView
