import View from './View.js'

import $ from 'jquery'
import 'bootstrap/js/dist/modal.js'

const tag = '[ModalView]'
const ModalView = Object.create(View)

ModalView.setup = function(el) {
  this.init(el)
  this.bindEvents()
  return this
}

ModalView.bindEvents = function() {
  Array.from(document.querySelectorAll('[data-toggle=modal]')).forEach(btn => {
    btn.addEventListener('click', e => this.onClick(e.currentTarget.getAttribute('data-target')))
  })
}

ModalView.onLoad = function() {
  // Bootstrap modal module 적용
  Array.from(document.querySelectorAll('.modal-dialog')).forEach(el => {
    const bsModalDialogClassName = 'modal-dialog'
    if (!el.classList.contains(bsModalDialogClassName)) {
      el.classList.add('modal-dialog')
    }
  })

  // Bootstrap backdrop 사용자 스타일링
  $('body').on('show.bs.modal', '.modal', () => {
    setTimeout(() => {
      const $bsModalBackdrop = $('.modal-backdrop')
      const backdropClassName = 'custom-modal-backdrop'
      if ($bsModalBackdrop.length > 0 && !$bsModalBackdrop.hasClass(backdropClassName)) {
        $bsModalBackdrop.addClass(backdropClassName)
      }
    })
  })
}

ModalView.onClick = function(id) {
  // const targetId = e.currentTarget.getAttribute('data-target').replace(/\#/g,'')
  $(id).on('show.bs.modal', function(e) {
    console.log('show.bs.modal')
  })
  $(id).on('shown.bs.modal', function(e) {
    console.log('shown.bs.modal')
    // do something...
  })
  $(id).on('hide.bs.modal', function(e) {
    console.log('hide.bs.modal')
    // do something...
  })
  $(id).on('hiden.bs.modal', function(e) {
    console.log('hiden.bs.modal')
    // do something...
  })
}

export default ModalView
