import MainContainerView from '../views/MainContainerView.js'
import StickyView from '../views/StickyView.js'
import MainmenuView from '../views/MainmenuView.js'
import TabView from '../views/TabView.js'
import ListView from '../views/ListView.js'
import CollapseView from '../views/CollapseView.js'
import ResponsiveListView from '../views/ResponsiveListView.js'
import PaginationView from '../views/PaginationView.js'
import FormView from '../views/FormView.js'
import ModalView from '../views/ModalView.js'
import CheckBrowser from '../helpers/CheckBrowser.js'
import 'classlist-polyfill/src/index.js'

const tag = '[MainController]'
const tabs = document.querySelector('.l-nav-tab')
const bodyClass = document.querySelector('html').classList

export default {
  init() {
    this.jsCheck()
    this.browerCheck()
    console.log(tag, 'init()')
    MainContainerView.setup(document.querySelector('.l-main'))
    StickyView.setup(document.querySelector('.l-header'))
    MainmenuView.setup(document.querySelector('#mainMenu'))
    ListView.setup(document.querySelector('.data-table'))
    CollapseView.setup(document.querySelector('.collapse-list'))
    ResponsiveListView.setup(document.querySelector('.board-lists > table'))
    PaginationView.setup(document.querySelector('[name="paginate_by"]'))
    FormView.setup(document.querySelectorAll('input[type=text]'))
    ModalView.setup(document.querySelector('.modal'))

    if (tabs) {
      TabView.setup(tabs)
        .on('@change', e => this.onChangeTab(e.detail.tabName))
    }

    this.currentUrl = window.location.href
    this.renderView()
  },

  jsCheck() {
    bodyClass.remove('no-js')
    bodyClass.add('js')
  },

  browerCheck() {
    const browserName = CheckBrowser()
    bodyClass.add(browserName)
  },

  renderView() {
    console.log(tag, 'renderView()')
    MainmenuView.setActiveNav(this.currentUrl)
  },

  onChangeTab(tabName) {
    const selectedText = TabView.trigger.getElementsByTagName('span')[0]

    if (selectedText.innerText) {
      selectedText.innerText = tabName
    } else if (selectedText.textContent) {
      selectedText.textContent = tabName
    }
  },
}
