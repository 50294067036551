import View from './View.js'

const tag = '[StickyView]'
const StickyView = Object.create(View)

StickyView.setup = function(el) {
  if (typeof(el) != 'undefined' && el != null) {
    this.init(el)
    this.bindScroll()
    return this
  }
}

StickyView.bindScroll = function() {
  const _self = this
  let refOffset = 0
  const setSticky = function() {
    let newOffset = window.scrollY || window.pageYOffset
    let stickyHeight = _self.el.offsetHeight

    if (newOffset > stickyHeight) {
      if (newOffset > refOffset) {
        _self.el.classList.add('sticky')
        _self.el.classList.add('fadeInDown')
        _self.el.classList.add('animated')
      } else {
        _self.el.classList.remove('fadeInDown')
        _self.el.classList.remove('sticky')
      }
      refOffset = newOffset
    }
  }

  window.addEventListener('scroll', setSticky, false)
}


export default StickyView
