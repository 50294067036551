import View from './View.js'
// import $ from 'jquery'
// import 'bootstrap/js/dist/collapse.js'

const tag = '[CollapseView]'
const CollapseView = Object.create(View)

CollapseView.setup = function() {
  if (typeof(el) != 'undefined' && el != null) {
    this.init(el)
    this.triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'))
    this.bindEvents()
    return this
  }
}

CollapseView.ariaToggle = function(btn) {
  btn.setAttribute(
    'aria-expanded',
    btn.getAttribute('aria-expanded') === 'false' ? 'true' : 'false'
  )
}

CollapseView.hasAttr = function(el, attr) {
  let hasAttr = false
  if (el.hasAttribute) {
    hasAttr = el.hasAttribute (attr);
  }
  else {
    if (el.getAttributeNode) {
      var attr = el.getAttributeNode (attr);
      if (attr) {
        hasAttr = attr.specified;
      }
    }
  }
  //console.log(hasAttr)
}

CollapseView.bindEvents = function(e) {
  const selectorMap = {
    trigger: '[data-toggle="collapse"]',
    collapse: '.collapse',
  }
  window.addEventListener('DOMContentLoaded', (e) => this.setDataCollapseAttr(selectorMap))

  const fnMap = {
    toggle: 'toggle',
    show: 'add',
    hide: 'remove',
  }
  window.addEventListener('click', (e) => {
    const trigger = e.target
    if (this.triggers.includes(trigger)) {
      const selector = trigger.getAttribute('data-collapse-target')
      this.collapse(selector, fnMap['toggle'])
      this.ariaToggle(trigger)
    }
  }, false)
}

CollapseView.collapse = (selector, fnMap) => {
  const targets = Array.from(document.querySelectorAll(`[data-collapse="${selector}"]`))
  targets.forEach(target => {
    target.classList[fnMap]('show');
  })
}

CollapseView.setDataCollapseAttr = function(selectorMap) {
  Array.from(this.el.querySelectorAll(selectorMap['collapse'])).forEach((el, i) => {
    if (!this.hasAttr(el, 'data-collapse')) {
      el.setAttribute('data-collapse', `collapse-${i}`)
    }
  })
  Array.from(this.el.querySelectorAll(selectorMap['trigger'])).forEach((trigger, i) => {
    if (!this.hasAttr(trigger, 'data-collapse-target')) {
      trigger.setAttribute('data-collapse-target', `collapse-${i}`)
    }
  })
}

export default CollapseView
