import View from './View.js'

const tag = '[PaginationView]'
const PaginationView = Object.create(View)

PaginationView.setup = function(el) {
  this.init(el)
  this.bindEvents()
  return this
}

PaginationView.bindEvents = function() {
  const submit_search_form = function (event) {
    document.forms['search_form'].submit()
  }

  const pagination = function() {
    if ( document.querySelector('select[name=paginate_by]') )
      document.querySelector('select[name=paginate_by]').onchange = submit_search_form
  }

  window.addEventListener('load', pagination, false)
}

export default PaginationView
