import View from './View.js'

const tag = '[ResponsiveListView]'
const ResponsiveListView = Object.create(View)

ResponsiveListView.setup = function(el) {
  if (typeof(el) != 'undefined' && el != null) {
    this.init(el)
    this.bindEvents()
    return this
  }
}

ResponsiveListView.bindEvents = function() {
  Array.from(this.el.querySelectorAll('tbody tr')).forEach(tr => {
    tr.addEventListener('click', e => this.onClickBoardRow(e))
  })
}

ResponsiveListView.onClickBoardRow = function(e) {
  const href = e.currentTarget.querySelector('a').getAttribute('href')
  if (href && window.matchMedia(`screen and (max-width: 992px)`).matches) {
    window.location = href
  }
}

export default ResponsiveListView
