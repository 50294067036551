import View from './View.js'

const tag = '[MainmenuView]'
const MainmenuView = Object.create(View)

const touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
const iOS5 = /iPad|iPod|iPhone/.test(navigator.platform) && 'matchMedia' in window

const body = document.getElementsByTagName('body')[0]
const main = document.querySelector('.l-main')
const navbarToggler = document.querySelector('#navbarToggler')
const navbarCollapsed = document.querySelector('#navbarCollapsed')

const navbarTogglerArr = [navbarToggler, main]
const navbarsCollapsed = [navbarCollapsed, main]

MainmenuView.setup = function(el) {
  this.init(el)
  if (typeof(el) != 'undefined' && el != null) {
    this.bindEvents()
    return this
  }
}

MainmenuView.setActiveNav = function(currentUrl) {
  Array.from(this.el.querySelectorAll('a')).forEach(a => {
    if (a.href === currentUrl) {
      a.parentElement.classList.add('active')
    }
  })
}

MainmenuView.setAria = function(el) {
  el.setAttribute('aria-haspopup', 'true')
  el.setAttribute('aria-expanded', 'false')
}

MainmenuView.ariaToggle = function(el) {
  if (el.hasAttribute('aria-expanded')) {
    el.setAttribute(
      'aria-expanded',
      el.getAttribute('aria-expanded') === 'false' ? 'true' : 'false'
    )
  }
}

MainmenuView.toggleState = function(el) {
  if (!el.classList.contains('is-open')) {
    el.classList.add('is-open')
  } else {
    el.classList.remove('is-open')
  }
}

MainmenuView.bindEvents = function() {
  window.addEventListener('DOMContentLoaded', () => this.onLoad())

  /* 햄버거 버튼 */
  navbarToggler.addEventListener('click', e => {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.openNavbarCollapsed(...navbarsCollapsed)
    this.ariaToggle(e.currentTarget)
  })
  navbarTogglerArr.forEach(el => {
    el.addEventListener('click', e => {
      if (main.classList.contains('is-open')) {
        this.closeNavbarCollapsed(...navbarsCollapsed)
      }
    })
  })

  /* PC 메뉴 */
  Array.from(this.el.children).forEach(li => {
    if (!touchsupport) {
      li.addEventListener('mouseover', e => this.handleMouse(e))
      li.addEventListener('mouseout', e => this.handleMouse(e))
    }
  })

  /* 리사이징 */
  const delta = 100
  let resizeTimeout = null
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(resizeThrottler, delta)
  }, false)

  function resizeThrottler() {
    //console.log('resize')
  }
}

MainmenuView.onLoad = function() {
  // 초기화
  const submenus = this.el.querySelectorAll('li ul')
  if (submenus.length) {
    Array.from(submenus).forEach(el => {
      if (!touchsupport) {
        this.setAria(el.parentElement.querySelector('a'))
      } else {
        el.parentElement.insertBefore(this.createMobileToggler(), el)
        this.setAria(el.parentElement.querySelector('button'))
      }
    })
  }
  navbarToggler.setAttribute('aria-expanded', 'false')

  // Mobile 메뉴
  const bodyClass = document.querySelector('html').classList
  if (iOS5 || touchsupport) bodyClass.add('touchsupport')

  const btns = this.el.querySelectorAll('.js-sub-toggler')
  Array.from(btns).forEach(btn => {
    btn.addEventListener('click', e => {
      this.toggleState(e.currentTarget.parentElement)
      this.toggleState(e.currentTarget.nextElementSibling)
      this.ariaToggle(e.currentTarget)
    })
  })
}

MainmenuView.createMobileToggler = function() {
  const btn = document.createElement('button')
  btn.setAttribute('type', 'button')
  btn.setAttribute('aria-haspopup', 'false')
  btn.setAttribute('aria-expanded', 'false')
  btn.innerHTML = '<i class="arrow"></i><span class="sr-only">하위메뉴 펼치기/닫기</span>'
  btn.className = 'js-sub-toggler'
  btn.style.position = 'absolute'
  btn.style.top = '0'
  btn.style.right = '0'
  btn.style.width = '50px'
  btn.style.height = '3.5rem'
  btn.style.padding = '0.5rem 1rem'
  btn.style.background = 'transparent'
  btn.style.border = 'none'
  btn.style.zIndex = '99'
  return btn
}

MainmenuView.handleMouse = function(e) {
  e.preventDefault()
  this.ariaToggle(e.currentTarget.querySelector('a'))
  this.toggleState(e.currentTarget)
}

MainmenuView.openNavbarCollapsed = function(...els) {
  [...els].forEach(el => el.classList.toggle('is-open'))
  body.classList.toggle('js-nav-active')
}

MainmenuView.closeNavbarCollapsed = function(...els) {
  [...els].forEach(el => el.classList.remove('is-open'))
  navbarToggler.setAttribute('aria-expanded', 'false')
  body.classList.remove('js-nav-active')
}

export default MainmenuView
