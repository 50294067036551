import View from './View.js'

const tag = '[ListView]'
const ListView = Object.create(View)

ListView.setup = function(el) {
  if (typeof(el) != 'undefined' && el != null) {
    this.init(el)
    this.bindEvents()
    return this
  }
}

ListView.bindEvents = function() {
  const checkAll = this.el.querySelector('.check-all')
  if (checkAll !== null) {
    checkAll.addEventListener('click', e => this.checkAll(e))
  }
}

ListView.checkAll = function(e) {
  const checkboxAll = this.el.querySelectorAll('.th-check input[type="checkbox"]')
  Array.from(checkboxAll).forEach(el => {
    if (el.type == 'checkbox' && el.name != 'check') {
      el.checked = e.target.checked
    }
  })
}

export default ListView
